import { template as template_f43f4d8b6f944477b9d99f90fef53ef9 } from "@ember/template-compiler";
const WelcomeHeader = template_f43f4d8b6f944477b9d99f90fef53ef9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
