import { template as template_9cb776e4321e4e4e957c6666c558e24f } from "@ember/template-compiler";
const FKText = template_9cb776e4321e4e4e957c6666c558e24f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
