import { template as template_953e1946e32149bca4c6adc79f06a785 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_953e1946e32149bca4c6adc79f06a785(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
